import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironment } from 'src/environments/baseEnvironment.preprod';

export const environment:Environment = {
  ...baseEnvironment,
	requestDatasheetUrl: 'https://merlo-sav.datalean-nodejs-preprod.catalean.com/merlo-sav/',
  organizationPrefix: 'MER',
	organizationUUID: '88811bb2-020a-4b4d-b400-13efabff1ace',
  activeLanguage: 'it-IT'
};
